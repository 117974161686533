import axios from "axios";


const config = { headers: { 'Content-Type': `multipart/form-data` } }

const API_URL = "https://kpgroupcontactbook.in/KPGroupAPI/api/";
// const API_URL = "http://api.technosmart.co.in/api/";


class services {
    //  API_URL = "http://api.technosmart.co.in/api/";
    API_URL = "https://kpgroupcontactbook.in/KPGroupAPI/api/";

    JsonValue(data) { return axios(data) }

    SetBanner(form) { return axios.post(API_URL + "Banner/SetBanner", form, config); }

    SetMulakatVisitDetail(form) { return axios.post(API_URL + "MulakatVisitDetail/SetMulakatVisitDetail", form, config); }
    SetArji(form) { return axios.post(API_URL + "ArjiMaster/SetArji", form, config); }
    SetInvitation(form) { return axios.post(API_URL + "Invitation/SetInvitation", form, config); }
    SetSendInvitation(form) { return axios.post(API_URL + "SendInvitation/SetSendInvitation", form, config); }
    SetCalendarEvent(form) { return axios.post(API_URL + "CalendarEvent/SetCalendarEvent", form, config); }
}
export default new services();
