import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";


const defaultMaterialTheme = createTheme();

const Bannermaster = () => {
    const headerTblStyle = { color: 'Black' };

    const cols = [
        { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '5px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        {
            title: (<div style={{ color: 'white', width: '900px' }}> <span>Banner Image</span></div>), width: '10%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => {
                return (
                    <p>
                        {
                            (rowData.Image != "" && rowData.Image != null) ? (
                                <a href={APP_URL + rowData.Image} target="_blank" rel="noopener noreferrer">
                                    <img src={APP_URL + rowData.Image} alt="Image" style={{ width: 150, height: 100, border: '1px solid black' }} />
                                </a>
                            ) : (
                                <img src={rowData.Image ? APP_URL + rowData.Image : "Images/Noimage.png"} alt="No Image" style={{ width: '80px', height: '80px', marginRight: '5px', border: '1px solid black' }} />
                            )

                        }
                    </p>)
            }
        },
    ]

    const APP_URL = process.env.REACT_APP_Banner_URL;
    const form = useRef(FormWithConstraints);

    const [BannerID, setBannerID] = useState('')

    const [BannerImage, setBannerImage] = useState('')
    const [OldBannerImage, setOldBannerImage] = useState('')
    const [error, setError] = useState('')
    const [BannerImageerrorMsg, setBannerImageerrorMsg] = useState(true)

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();

    useEffect(() => {
        GetBanner(pageCount);
    }, []);

    const GetBanner = (pageCount) => {
        debugger;
        var data = JSON.stringify({
            "BannerID": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Banner/GetBanner",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }
        }, error => { })
    }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger;
        console.log(pageId, "pageID", pageCount)

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.BannerID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "BannerID": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Banner/GetBanner",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };

    const HandleBannerImage = (e) => {
        setError('');
        setBannerImage('');

        if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setBannerImageerrorMsg(true)
        }
        else {
            setBannerImageerrorMsg(false)
        }

        if (e.target.files.length > 0) {
            setBannerImage(e.target.files);
        }
        setOldBannerImage('');
    };

    const submitHandler = (e) => {
        e.preventDefault();

        var allvalid = true;

        if (!BannerID && BannerImage.length === 0) {
            setError('Please Select Banner Image');
            allvalid = false;
        }

        if (allvalid && BannerImageerrorMsg) {
            Swal.fire({
                title: 'Are you sure?',
                // text: 'You are about to submit the form.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Continue with the submission logic
                    var form = new FormData();
                    if (BannerID) {
                        form.append('BannerID', BannerID);
                        form.append('OldBannerImage', OldBannerImage);

                        for (let i = 0; i < BannerImage.length; i++) {
                            form.append('BannerImage', BannerImage[i]);
                        }
                    } else {
                        form.append('BannerID', "");
                        form.append('BannerImage', BannerImage[0]);
                    }

                    services.SetBanner(form).then(response => {
                        if (response.data.status === 1) {
                            if (BannerID) {
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success", timer: 1500
                                });
                                setIssubmitvisible(true);
                                setIsUpdatevisible(false);
                            } else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", timer: 1500
                                });
                            }
                            GetBanner(pageCount);
                            ClearData();
                        }
                    }).catch(error => { });
                }
            });
        }
    };

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setBannerID('')
        setOldBannerImage('')
        setBannerImageerrorMsg(true)
        setError('')
        ref.current.value = ''
    }

    const ClearData = () => {
        setBannerID('')
        setBannerImage('')
        setOldBannerImage('')
        setError('')
        ref.current.value = ''
    }

    const EditBanner = (id) => {
        debugger;
        setError('')
        setOldBannerImage('')
        setBannerImageerrorMsg(true)
        setIsUpdatevisible(true)
        setIssubmitvisible(false)

        var data = JSON.stringify({

            "BannerID": id,
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Banner/GetBanner",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {

            setBannerID(response.data.data[0].BannerID)
            setOldBannerImage(response.data.data[0].Image)

        }, error => { })
    }

    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({

                });
                var config = {
                    method: 'GET',
                    url: services.API_URL + "Banner/DeleteBanner/" + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetBanner(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Banner Master</h5>
                            </div>
                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">

                                    <div className='row'>
                                        <div className="col-md-3">
                                            <label htmlFor="fileInput" className="form-label fw-semibold">Banner Image </label>
                                            <input type="file" ref={ref} className="form-control" onChange={HandleBannerImage} accept="image/jpeg, image/png, image/jpg, image/PNG" style={{ height: "37px" }} />
                                            {OldBannerImage !== "" && (<label style={{ color: "Red" }}>{OldBannerImage ? OldBannerImage : ""} </label>)}
                                            {!BannerImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                                            {error && <p style={{ color: 'red' }}>{error}</p>}
                                        </div><br></br>

                                        <div className="col-md-3" style={{ marginTop: '2%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditBanner(r.BannerID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.BannerID, r.Image)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '3px', marginBottom: '0.3%' }}>
                                                    <h5>View Banner : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Bannermaster
